/** @format */

import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { FunctionComponent as FC, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { breakpointFrom, breakpointUpTo } from '../../theme/mixins';
import theme from '../../theme/styled-theme';
import { ImageTextBase, ImageTextBigText, ImageTextFields } from '../../types/page';
import { FindColor } from '../../utils/findThemeColor';
import { Image } from '../Image';
import { Container, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { PBig } from '../ui/Typography';

export const ImageText: FC<Props> = ({ text, images, alignment, rotateImage, background, type }) => {
  const withBigText = useMemo(() => type === 'withBigText', [type]);
  const image = useMemo(() => images[0], [images]);
  const Text = () => (withBigText ? <RichText text={text} customOptions={BigTextOptions} /> : <RichText text={text} />);

  return (
    <StyledContainer $color={FindColor(background, theme.color)}>
      <Grid>
        <TextWrapper $alignment={alignment}>
          <Text />
        </TextWrapper>
        <ImageWrapper $rotate={rotateImage} $alignment={alignment}>
          <Image image={image} />
        </ImageWrapper>
      </Grid>
    </StyledContainer>
  );
};

type Props = ImageTextFields & (ImageTextBase | ImageTextBigText);

const Grid = styled(Container)`
  display: grid;
  align-items: center;
  gap: 2rem;
  padding-block: 2.5rem;

  ${breakpointFrom.xxl`
     gap:0;
  `}

  ${breakpointFrom.md`
   grid-template-columns: repeat(2, 1fr);

  `}

  h2,
  p {
    color: ${({ theme }) => theme.color.teal.dark};
  }

  h2 {
    font-size: ${({ theme }) => `clamp(${theme.font.size[1.5]}, 1.3702rem + 0.5769vw, ${theme.font.size[2.0625]})`};
    line-height: clamp(1.75rem, 1.6058rem + 0.641vw, 2.375rem);
    em {
      color: ${({ theme }) => theme.color.teal.light};
      line-height: clamp(1.75rem, 1.6058rem + 0.641vw, 2.375rem);
      font-size: ${({ theme }) => `clamp(${theme.font.size[1.75]}, 1.6058rem + 0.6410vw, ${theme.font.size[2.375]})`};
    }
  }

  p {
    margin-top: 1.5rem;
  }

  p + h2 {
    margin-top: clamp(1.75rem, 1.6667rem + 0.3704vw, 2rem);
  }

  a {
    margin: 4rem 0;
    ${breakpointUpTo.md`
     margin: 1rem 0;
  `}
  }
`;

const TextWrapper = styled.div<{ $alignment: string }>`
  position: relative;

  max-width: 27rem;
  margin-inline: auto;

  ${breakpointFrom.md`
    grid-row:1;
    grid-column: ${({ $alignment }: { $alignment: string }) => ($alignment === 'Left' ? 1 : 2)};
  `}

  img:first-child:not(:only-child) {
    ${breakpointUpTo.sm`
    scale: 0.9;
   `}
  }
`;

const StyledContainer = styled(ContainerWrapper)`
  ${breakpointUpTo.md`
    padding-block: 1.5rem 0;
  `}
`;

const ImageWrapper = styled.div<{ $rotate: boolean; $alignment: string }>`
  position: relative;
  max-width: 30rem;
  margin-inline: auto;

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    display: block;

    ${({ $rotate }) =>
      $rotate &&
      breakpointUpTo.md`
      transform: rotate(-10deg) scale(1.3) ;
      margin-block: 20%;
    `}
  }
`;

const BigTextOptions: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <>{text}</>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // to avoid rendering empty paragraphs
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return <PBig>{children}</PBig>;
    },
  },
};
