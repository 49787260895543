/** @format */

import { FunctionComponent as FC, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { breakpointFrom, breakpointUpTo } from '../../theme/mixins';
import { ImageTextFields, ImageTextManifest as ManifestProps } from '../../types/page';
import { FindColor } from '../../utils/findThemeColor';
import { Image } from '../Image';
import { Container, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';

export const ImageTextManifest: FC<Props> = ({ text, images, alignment, rotateImage, background }) => {
  const theme = useContext(ThemeContext);
  const image = useMemo(() => images[0], [images]);

  return (
    <StyledContainer $color={FindColor(background, theme?.color)}>
      <Grid>
        <TextWrapper $alignment={alignment}>
          <RichText text={text} />
        </TextWrapper>
        <ImageWrapper $rotate={rotateImage} $alignment={alignment}>
          <Image image={image} />
        </ImageWrapper>
      </Grid>
    </StyledContainer>
  );
};

type Props = ImageTextFields & ManifestProps;

const Grid = styled(Container)`
  display: grid;
  align-items: center;
  gap: 2rem;
  padding-block: 2.5rem;

  ${breakpointFrom.xxl`
     gap:0;
  `}

  ${breakpointUpTo.md`
   display: flex;
   flex-direction: column-reverse;
   gap:0;

  `}


  ${breakpointFrom.md`
   grid-template-columns: repeat(2, 1fr);

  `}

  h2,
  p {
    color: ${({ theme }) => theme.color.teal.dark};
  }

  h2 {
    font-size: ${({ theme }) => `clamp(${theme.font.size[1.5]}, 1.3702rem + 0.5769vw, ${theme.font.size[2.0625]})`};
    line-height: clamp(1.75rem, 1.6058rem + 0.641vw, 2.375rem);
    em {
      color: ${({ theme }) => theme.color.teal.light};
      line-height: clamp(1.75rem, 1.6058rem + 0.641vw, 2.375rem);
      font-size: ${({ theme }) => `clamp(${theme.font.size[1.75]}, 1.6058rem + 0.6410vw, ${theme.font.size[2.375]})`};
    }
  }

  p {
    margin-top: 1.5rem;
  }

  p + h2 {
    margin-top: clamp(1.75rem, 1.6667rem + 0.3704vw, 2rem);
  }

  a {
    margin: 4rem 0;
    ${breakpointUpTo.md`
     margin: 1rem 0;
  `}
  }
`;

const TextWrapper = styled.div<{ $alignment: string }>`
  position: relative;
  z-index: 3;
  left: ${({ $alignment }) => ($alignment === 'Right' ? 'clamp(-5rem, 5.9091rem + -12.1212vw, 0rem);' : '5rem')};
  max-width: 27rem;
  margin-inline: auto;

  h2 + p {
    font-weight: 500 !important;
  }

  h2 {
    font-size: clamp(2.5rem, 1.4437rem + 4.507vw, 5.5rem);
    line-height: clamp(3rem, 2.0833rem + 4.0741vw, 5.75rem) !important;
    color: ${({ theme }) => theme.color.yellow};
    line-height: 5.75rem;
  }
  ${breakpointFrom.md`
    grid-row:1;
    grid-column: ${({ $alignment }: { $alignment: string }) => ($alignment === 'Left' ? 1 : 2)};
  `}
  ${breakpointUpTo.md`
    left: 0;
    top: -3rem;
  `}

  img:first-child:not(:only-child) {
    ${breakpointUpTo.sm`
    scale: 0.9;
   `}
  }
`;

const StyledContainer = styled(ContainerWrapper)`
  ${breakpointUpTo.md`
    padding-block: 1.5rem 0;
  `}
`;

const ImageWrapper = styled.div<{ $rotate: boolean; $alignment: string }>`
  position: relative;
  left: ${({ $alignment }) => ($alignment === 'Right' ? '6rem' : 'clamp(-5rem, 0.9091rem + -12.1212vw, -5rem);')};
  max-width: 100%;
  margin-inline: auto;

  ${breakpointUpTo.md`
    left:0;
  `};

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    display: block;

    ${({ $rotate }) =>
      $rotate &&
      breakpointUpTo.md`
      transform: rotate(-10deg) scale(1.3) ;
      margin-block: 20%;
    `}

    ${breakpointFrom.xxl`
     max-width: 130%;
  `}
    ${breakpointFrom.xl`
     max-width: 115%;
  `}
  }
`;
