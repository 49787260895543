/** @format */

import styled from 'styled-components';
import { breakpointUpTo } from '../../theme/mixins';

interface Props {
  label: string;
}

const ButtonTarget = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: auto;
  height: auto;
  border: none;
  border-radius: 2.25rem;
  transition: background 0.5s cubic-bezier(0.36, 0.33, 0, 1);
  padding: 0.25rem 1rem;
  background: ${({ theme }) => theme.color.yellow};
  color: ${({ theme }) => theme.color.teal.dark};
  p {
    margin: 0 !important;
  }

  ${breakpointUpTo.sm`
  line-break: anywhere;
  `}
`;

const Wrapper = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  h4 {
    font-weight: 600;
    color: ${({ theme }) => theme.color.teal.dark};
  }
`;
const Container = styled.div`
  padding: 3.5rem 0;
  hr {
    border: 1px solid ${({ theme }) => theme.color.teal.dark};
  }
`;

export const Target = ({ label }: Props) => {
  return (
    <Container>
      <hr />
      <Wrapper>
        <h4>Target</h4>
        <ButtonTarget>
          <p>{label}</p>
        </ButtonTarget>
      </Wrapper>
      <hr />
    </Container>
  );
};
