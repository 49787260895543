/** @format */

import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { FunctionComponent as FC, ReactNode, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useMediaQueryIndex } from '../../hooks/useMediaQuery';
import { breakpointFrom, breakpointUpTo } from '../../theme/mixins';
import { ImageTextBanner as BannerProps, ExternalLink, ImageTextFields, InternalLink } from '../../types/page';
import { FindColor } from '../../utils/findThemeColor';
import { Image } from '../Image';
import { Container, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { P } from '../ui/Typography';
import { StyledButton } from '../ui/Button/StyledButton';
import { Entry } from 'contentful';
import { MagicLink } from '@/utils/magicLink';

export const ImageTextBanner: FC<Props> = ({ text, images, alignment, rotateImage, background }) => {
  const theme = useContext(ThemeContext);
  const mediaQueryIndex = useMediaQueryIndex();
  const desktop = !!(mediaQueryIndex !== null && mediaQueryIndex >= 2);
  const mobile = !!(mediaQueryIndex !== null && mediaQueryIndex < 2);

  const image = useMemo(() => images[0], [images]);

  return (
    <StyledContainer $color={FindColor(background, theme?.color)}>
      <Grid>
        <TextWrapper $alignment={alignment}>
          <RichText text={text} customOptions={OPTIONS} />
          {mobile && (
            <ImageWrapper $rotate={rotateImage} $alignment={alignment}>
              <Image className="logo-img-mobile" image={image} />
            </ImageWrapper>
          )}
        </TextWrapper>
        {desktop && (
          <ImageWrapper $rotate={rotateImage} $alignment={alignment}>
            <Image image={image} />
          </ImageWrapper>
        )}
      </Grid>
    </StyledContainer>
  );
};

type Props = ImageTextFields & BannerProps;

const Grid = styled(Container)`
  display: grid;
  align-items: center;
  gap: 2rem;

  ${breakpointFrom.xxl`
     gap:0;
  `}

  padding-block: 2.5rem;

  ${breakpointFrom.md`
    grid-template-columns: repeat(2, 1fr);
  `}

  h2,
  p {
    color: ${({ theme }) => theme.color.teal.dark};
  }

  h2 {
    font-size: ${({ theme }) => `clamp(${theme.font.size[1.5]}, 1.3702rem + 0.5769vw, ${theme.font.size[2.0625]})`};
    line-height: clamp(1.75rem, 1.6058rem + 0.641vw, 2.375rem);
    em {
      color: ${({ theme }) => theme.color.teal.light};
      line-height: clamp(1.75rem, 1.6058rem + 0.641vw, 2.375rem);
      font-size: ${({ theme }) => `clamp(${theme.font.size[1.75]}, 1.6058rem + 0.6410vw, ${theme.font.size[2.375]})`};
    }
  }

  p {
    margin-top: 1.5rem;
    color: ${({ theme }) => theme.color.white};

    ${breakpointUpTo.md`
      padding: 0 clamp(1.88rem, 0.5067rem + 6.1037vw, 6rem);
    `}
  }

  p + h2 {
    margin-top: clamp(1.75rem, 1.6667rem + 0.3704vw, 2rem);
  }

  a {
    margin: 4rem 0;
    ${breakpointUpTo.md`
     margin: 1rem 0;
  `}
  }
`;

const TextWrapper = styled.div<{ $alignment: string }>`
  position: relative;
  max-width: 27rem;
  margin-inline: auto;

  .logo {
    height: 5rem;
    width: auto;
    max-width: 100%;
    object-fit: contain;
    display: block;
  }

  ${breakpointFrom.md`
    grid-row:1;
    grid-column: ${({ $alignment }: { $alignment: string }) => ($alignment === 'Left' ? 1 : 2)};
  `}
  ${breakpointUpTo.md`
      max-width: 100%;
      display: grid;

      gap: 1rem;
      justify-items: center;


  `}

  a {
    ${breakpointUpTo.md`
      order: 4;
    `}
  }

  p {
    ${breakpointUpTo.md`
      order: 3;
    `}
  }

  img:first-child:not(:only-child) {
    ${breakpointUpTo.sm`
      scale: 0.9;
    `}
  }
`;

const StyledContainer = styled(ContainerWrapper)`
  ${breakpointUpTo.md`
    padding-block: 1.5rem 0;
  `}
`;

const ImageWrapper = styled.div<{ $rotate: boolean; $alignment: string }>`
  position: relative;
  max-width: 100%;
  margin-inline: auto;

  ${breakpointUpTo.md`
  left:${({ $manifest }: { $manifest: boolean }) => $manifest && 0};
   max-width: ${({ $banner }: { $banner: boolean }) => $banner && '100%'};
  `};

  img {
    min-width: 100%;
    max-width: calc(100% + 3rem);
    height: auto;
    position: relative;
    left: -1.5rem;
    display: block;
    object-fit: cover;
    height: 18rem;

    ${breakpointFrom.sm`
      min-width: none;
      max-width: 100%;
      left: 0;
      border-radius: 1.56rem;
    `};

    ${breakpointFrom.md`
      height: 29rem;
    `};

    ${({ $rotate }) =>
      $rotate &&
      breakpointUpTo.md`
      transform: rotate(-10deg) scale(1.3) ;
      margin-block: 20%;
    `}

    ${breakpointFrom.xxl`
      max-width: 130%;
    `}

    ${breakpointFrom.xl`
      max-width: 100%;
    `}
  }
`;
const OPTIONS: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // to avoid rendering empty paragraphs
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return <P>{children}</P>;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      if (node.data.target.fields.file.contentType.includes('image')) {
        return <Image image={node.data.target} className="logo" height={80} />;
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      if (
        node.data.target.sys.contentType.sys.id === 'internalLink' ||
        node.data.target.sys.contentType.sys.id === 'externalLink'
      ) {
        const item: Entry<InternalLink['fields'] | ExternalLink['fields']> = node.data.target;
        return (
          <StyledButton
            item={item}
            position={item.fields.position ?? 'right'}
            variant={item.fields.variant ?? 'bgTeal'}
            elementType="magic"
          >
            {item.fields.label}
          </StyledButton>
        );
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      if (
        node.data.target.sys.contentType.sys.id === 'internalLink' ||
        node.data.target.sys.contentType.sys.id === 'externalLink'
      ) {
        const item: Entry<InternalLink['fields'] | ExternalLink['fields']> = node.data.target;

        return <MagicLink item={item} />;
      }
    },
  },
};
